<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="">
              <div>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records">
                      <div class="grid">
                        <template v-for="(data, i) of records" :key="i">
                          <div class="col-12">
                            <h6 class="mb-2">Selected Items</h6>
                          </div>
                          <div class="col-12 mt-4">
                            <div
                              class="card mb-0"
                              :class="{
                                'item-selected': isCurrentRecord(data),
                              }"
                            >
                              <div class="grid">
                                <div class="col-fixed" style="width: 70px">
                                  <img
                                    style="width: 90%"
                                    :src="
                                      $utils.setFileUrl(
                                        data.third_party_agencies_agency_logo
                                      )
                                    "
                                  />
                                </div>
                                <div class="col">
                                  <div
                                    class="text-primary font-bold mb-2"
                                    v-ripple
                                  >
                                    {{
                                      data.third_party_agencies_grants_grant_name
                                    }}
                                  </div>
                                  <div class="text-sm text-500" lines="2">
                                    {{ data.third_party_agencies_agency_name }}
                                  </div>
                                  <div
                                    v-if="data.grant_additional_info"
                                    class="text-sm text-gray-500"
                                  >
                                    <div
                                      class="my-2 text-sm text-blue-400 text-500"
                                    >
                                      Additional Information Provided
                                    </div>
                                    <div
                                      v-for="(definition, index) in JSON.parse(
                                        data.grant_additional_info
                                      )"
                                      :key="index"
                                      class="col-12 md:col-6"
                                    >
                                      <div class="grid">
                                        <div class="">
                                          <div class="text-sm text-500">
                                            <span>
                                              {{ definition.label }}:
                                            </span>
                                            <span class="font-bold">
                                              {{ definition.value }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="mb-2 font-bold text-sm"></div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="showEditButtons"
                                  class="col-fixed text-right"
                                  style="width: auto"
                                >
                                  <div class="flex justify-content-end">
                                    <SplitButton
                                      class="p-button p-button-secondary p-button-text p-button-sm"
                                      :model="getActionMenuModel(data)"
                                    >
                                      <i></i>
                                    </SplitButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div
                        v-show="loading"
                        class="flex align-items-center justify-content-center text-gray-500"
                      >
                        <div>
                          <ProgressSpinner style="width: 20px; height: 20px" />
                        </div>
                      </div>
                    </template>
                    <!-- end of empty record-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ListPageMixin } from "../../mixins/listpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "listApplicantselectedgrantsPage",
  components: {},
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: "applicant_selected_grant_id",
    },
    pageName: {
      type: String,
      default: "applicant_selected_grants",
    },
    routeName: {
      type: String,
      default: "applicant_selected_grantslist",
    },
    apiPath: {
      type: String,
      default: "applicant_selected_grants/index",
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    multiCheckbox: {
      type: Boolean,
      default: true,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to remove this item from your list?",
    },
    showEditButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "Applicant Selected Grants";
      },
    },
    records: {
      get: function () {
        return this.$store.getters["applicant_selected_grants/records"];
      },
      set: function (value) {
        this.$store.commit("applicant_selected_grants/setRecords", value);
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicant_selected_grants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicant_selected_grants/setCurrentRecord", value);
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("applicant_selected_grants", [
      "fetchRecords",
      "deleteRecord",
    ]),
    getActionMenuModel(data) {
      return [
        {
          label: "Modify",
          command: (event) => {
            this.openPageDialog(
              {
                page: "applicant_selected_grants/edit",
                url: `/applicant_selected_grants/edit/${data.applicant_selected_grant_id}`,
              },
              { closeBtn: true }
            );
          },
          icon: "pi pi-pencil",
        },
        {
          label: "Remove",
          command: (event) => {
            this.deleteItem(data.applicant_selected_grant_id);
          },
          icon: "pi pi-minus-circle",
        },
      ];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords,
        };
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            // window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
